enum CostModifierId {
    FRENCH_CASTLE = 'FRENCH_CASTLE',
    ENLISTMENTS_INCENTIVES = 'ENLISTMENTS_INCENTIVES',
    ABBASID_TRADER_DISCOUNT = 'ABBASID_TRADER_DISCOUNT',
    FRESH_FOOD = 'FRESH_FOOD',
    ENGLISH_DOCKS = 'ENGLISH_DOCKS',
    ZHU_XIS_LEGACY_YUAN_DYNASTY = 'ZHU_XIS_LEGACY_YUAN_DYNASTY',
    AYYUBIDS_GOLDEN_AGE_4 = 'AYYUBIDS_GOLDEN_AGE_4',
    JAPANESE_FISHING_BOATS = 'JAPANESE_FISHING_BOATS'
}

export default CostModifierId;
