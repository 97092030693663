enum ProductionSpeedModifierId {
    MILITARY_ACADEMY = 'MILITARY_ACADEMY',
    FRENCH_DARK_AGE = 'FRENCH_DARK_AGE',
    FRENCH_FEUDAL_AGE = 'FRENCH_FEUDAL_AGE',
    FRENCH_CASTLE_AGE = 'FRENCH_CASTLE_AGE',
    FRENCH_IMPERIAL_AGE = 'FRENCH_IMPERIAL_AGE',
    SCHOOL_OF_CAVALRY = 'SCHOOL_OF_CAVALRY',
    SONG_DYNASTY = 'SONG_DYNASTY',
    CHINESE_DOCKS = 'CHINESE_DOCKS',
    GOLDEN_AGE_3 = 'GOLDEN_AGE_3',
    ENGLISH_MMA = 'ENGLISH_MMA',
    OTTOMANS_BLACKSMITH_AGE_2 = 'OTTOMANS_BLACKSMITH_AGE_2',
    OTTOMANS_BLACKSMITH_AGE_3 = 'OTTOMANS_BLACKSMITH_AGE_3',
    OTTOMANS_BLACKSMITH_AGE_4 = 'OTTOMANS_BLACKSMITH_AGE_4',
    ISTANBUL_OBSERVATORY = 'ISTANBUL_OBSERVATORY',
    AYYUBIDS_GOLDEN_AGE_3 = 'AYYUBIDS_GOLDEN_AGE_3',
    CONSCRIPTIO_1 = 'CONSCRIPTIO_1',
    CONSCRIPTIO_2 = 'CONSCRIPTIO_2',
    CONSCRIPTIO_3 = 'CONSCRIPTIO_3',
    CONSCRIPTIO_4 = 'CONSCRIPTIO_4',
    CONSCRIPTIO_5 = 'CONSCRIPTIO_5',
    WHITE_TOWER = 'WHITE_TOWER'
}

export default ProductionSpeedModifierId;
