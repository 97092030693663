enum ResourceDropOffModifierId {
    CUPELLATION = 'CUPELLATION',
    IMPROVED_CUPELLATION = 'IMPROVED_CUPELLATION',
    FOOD_IMPERIAL_OFFICIAL = 'FOOD_IMPERIAL_OFFICIAL',
    WOOD_IMPERIAL_OFFICIAL = 'WOOD_IMPERIAL_OFFICIAL',
    GOLD_IMPERIAL_OFFICIAL = 'GOLD_IMPERIAL_OFFICIAL',
    STONE_IMPERIAL_OFFICIAL = 'STONE_IMPERIAL_OFFICIAL',
    STEPPE_REDOUBT = 'STEPPE_REDOUBT',
    WOODEN_FORTRESS = 'WOODEN_FORTRESS',
    IMPROVED_PROCESSING = 'IMPROVED_PROCESSING',
}

export default ResourceDropOffModifierId;