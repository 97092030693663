import discordIcon from '../../../icons/discord.png';

function SocialMediaLinks() {
    return (
        <div>
            <a href='https://discord.gg/RVXctB83gc' target={'_blank'} rel="noreferrer"><img src={discordIcon} alt='discord'/></a>
        </div>
    );
}

export default SocialMediaLinks;
