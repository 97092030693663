import s from './hre-features.module.css';

function HreFeatures() {
    return (
        <div className={s.hreFeatures}>
            <div>Hre economy is just insane. You do not need calculations. Just do it:</div>
            <div>1. go fc with towers/pikes</div>
            <div>2. make sure you are not dead in early castle, build some army, take some relics</div>
            <div>3. build Palace of Swabia</div>
        </div>
    );
}

export default HreFeatures;
