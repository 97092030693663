enum PassiveIncomeSource {
    SULTANHANI_TRADE_NETWORK = 'SULTANHANI_TRADE_NETWORK',
    MANSA_QUERY_GOLD = 'MANSA_QUERY_GOLD',
    MANSA_QUERY_STONE = 'MANSA_QUERY_STONE',
    PIT_MINE = 'PIT_MINE',
    PIT_MINE_HOUSE = 'PIT_MINE_HOUSE',
    PIT_MINE_MINING_CAMP = 'PIT_MINE_MINING_CAMP',
    CATTLE_RANCH_CATTLE = 'CATTLE_RANCH_CATTLE',
    FULANI_CARROL_CATTLE = 'FULANI_CARROL_CATTLE',
    RELICS = 'RELICS',
    SACRED_SITES = 'SACRED_SITES',
    PAGODA = 'PAGODA',
    MEDITATION_GARDEN_BUSH = 'MEDITATION_GARDEN_BUSH',
    MEDITATION_GARDEN_GOLD = 'MEDITATION_GARDEN_GOLD',
    MEDITATION_GARDEN_STONE = 'MEDITATION_GARDEN_STONE',
    MEDITATION_GARDEN_TREE = 'MEDITATION_GARDEN_TREE',
    YORISHIRO_FARM_HOUSE = 'YORISHIRO_FARM_HOUSE',
    YORISHIRO_LUMBER_CAMP = 'YORISHIRO_LUMBER_CAMP',
    YORISHIRO_FORGE = 'YORISHIRO_FORGE'
}

export default PassiveIncomeSource;
